<template>
  <div class="home-con">
    <van-cell-group title="用户信息" title-class="cell-title">
      <van-cell title-class="cell-title" center title="用户名" :value="user.username" />
      <!-- <van-cell title-class="cell-title" center title="注册时间" :value="user.registered_time" /> -->
      <van-cell title-class="cell-title" center title="预付费金额" :value="user.prepaid_amount" />
      <van-cell title-class="cell-title" center title="充值消费记录" is-link to="user_center_pay_rec_log" />
      <van-cell title-class="cell-title" center title="换卡记录" is-link to="user_center_replace_code_rec_log" />
      <van-cell title-class="cell-title" center title="折扣" :value="user.discount" />
      <!-- <van-cell title-class="cell-title" center title="注册平台" :value="user.register_platform" /> -->
    </van-cell-group>
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'Home',
  data(){
    return {
      user:{},
    }
  },
  computed:{

  },
  components: {

  },
  methods:{
    onClickLeft(){
      this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    readOneAdmin(){
      api.admin.readOneAdmin().then(res=>{
        let r = res.data
        this.user = r.data
      })
    }
  },
  mounted(){
    this.user = this.$store.state.user
    this.readOneAdmin();
  }
}
</script>
<style lang="scss" scoped>
.home-con{
  .cell-title{
    text-align: left;
  }

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
  .van-radio{
    margin-bottom:5px
  }
  .copy-input{
    width: 1px;
    resize: none;
    border: none;
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
  }
}
</style>